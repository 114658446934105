import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import React, { ReactElement, ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { ApiContext, STORE_API_BASE } from '../api/api-provider';
import { Address, Billing, CartData, CartItem, CheckoutData, Order, PaymentData, PaymentGateway, PaymentMethod, StoreApiError } from '../api/types';
import { handleApiError } from '../api/utils';
import useTracking from '../tracking/use-tracking';

export const QUANTITY_MIN = 1;
export const QUANTITY_MAX = 99;

type CheckoutArgs = {
  billing: Partial<Billing>;
  shipping: Partial<Address>;
  paymentGateway: PaymentGateway;
  paymentData?: PaymentData;
};

type ErrorResponse = {
  error: string;
};

export type CartContextValue = {
  cart?: CartData;
  newestItem?: CartItem;
  fetch: () => Promise<AxiosResponse<CartData, unknown> | undefined>;
  addItem: (id: number, quantity: number) => Promise<void>;
  removeItem: (key: string) => Promise<void>;
  updateItem: (key: string, quantity: number) => Promise<void>;
  clearItems: () => Promise<void>;
  addCoupon: (code: string) => Promise<void>;
  removeCoupon: (code: string) => Promise<void>;
  clearCoupons: () => Promise<void>;
  clearCart: () => Promise<void>;
  isLoading: boolean;
  updateCustomer: (billing?: Partial<Billing>, shipping?: Partial<Address>) => Promise<void>;
  draftOrder: () => Promise<CheckoutData | undefined>;
  updateOrderWithPaypalData: (id: string, paypalData: any, paymentMethod: string) => Promise<Order | undefined>;
  checkout: (args: CheckoutArgs) => Promise<CheckoutData | undefined>;
  createPaypalOrderDetails: (cart: CartData, orderId: string, paymentMethod: PaymentMethod) => any;
  createPaypalExpressOrderDetails: (cart: CartData, orderId: string, paymentMethod: string) => any;
  confirmOrder: (orderId: string, payload: any) => Promise<Order | undefined>; 
  addOrRemoveDepositInBaset: (items: CartData["items"], type: "add" | "update") => Promise<void>;
  fetchItemBySlug: (slug: string) => Promise<any>;
  fetchOrderData: (orderId: string | null, firstName: string | null, lastName: string | null, zip?: string | null) => Promise<Order | undefined>;
};

interface LooseObject {
  [key: string]: any
}

export const CartContext = createContext<CartContextValue>({
} as CartContextValue);

type Props = {
  children: ReactNode;
};

export default function CartProvider({ children }: Props): ReactElement {
  const [data, setData] = useState<CartData>();
  const [isLoading, setIsLoading] = useState(false);
  const [newestItem, setNewestItem] = useState<CartItem>();
  const nonceRef = useRef<string>();

  const { userId, client } = useContext(ApiContext);

  const {
    getTrackingItem,
    getTrackingItems,
    getTrackingCoupons,
    getTrackingPrice,
    trackAddItem,
    trackPurchase,
  } = useTracking();

  const fetch = useCallback(async (): Promise<AxiosResponse<CartData, unknown> | undefined> => {
    try {
      const response = await client.get<CartData>(`${STORE_API_BASE}/cart`);
      setData(response.data);

      return response;
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client]);

  const extractNonce = useCallback((response?: AxiosResponse<unknown, unknown>) => {
    if(!response)  return;

    nonceRef.current = response?.headers.nonce;
  }, []);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const response = await fetch();
        extractNonce(response);
      } catch(error) {
        await handleApiError(error as AxiosError<StoreApiError>);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // useEffect(() => {
  //   (async (): Promise<void> => {
  //     try {
  //       const response = await client.get(`/hdc/v1/refresh_nonce`)
  //       response.headers.nonce = response.data

  //       extractNonce(response);
  //     } catch(error) {
  //       await handleApiError(error as AxiosError<StoreApiError>);
  //     }
  //   })();
  // }, [])

  const getRequestConfig = useCallback((): AxiosRequestConfig<unknown> => {
    return {
      headers: {
        Nonce: nonceRef.current,
      },
    };
  }, []);

  const getItem = useCallback((id: number, items: CartItem[]) => {
    return items.find((item) => item.id === id);
  }, []);

  const fetchItemBySlug = useCallback(async (slug: string) => {
    try {
      const response = await client.get(`${STORE_API_BASE}/products/${slug}`);
      return response.data;
    }catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    } 
  }, [client, extractNonce, getRequestConfig]);

  const addOrRemoveDepositInBaset = useCallback(async (cartItems:CartData["items"]) => {
    const depositProductId = process.env.GATSBY_DEPOSIT_PRODUCT_ID 

    if (!depositProductId) {
      return
    }

    try {
      let depositCounter = 0

      if (cartItems) {
        cartItems.forEach((item) => {
          const isDepositEligible = item.item_data?.some((itemData) => itemData.type === 'is_eligible_for_deposit' && itemData.value == 'true')
          if (isDepositEligible) {
            const deposit_multiplier = item.item_data.find((itemData) => itemData.type === 'deposit_multiplier')?.value
            depositCounter += deposit_multiplier ? parseInt(deposit_multiplier) * item.quantity : 0
          }
        })
      }

      const isDepositProductPresent = cartItems.some((item) => item.name === "Pfand 0,25€")
      const depositProductKey = cartItems.find((item) => item.id === Number(depositProductId))?.key



      if (depositCounter === 0 && isDepositProductPresent){
        const response = await client.post<CartData>(`${STORE_API_BASE}/cart/remove-item`, {
          key: depositProductKey,
        }, getRequestConfig());
        extractNonce(response);
        setData(response.data);
        return
      } 

      if (isDepositProductPresent) {
        // console.log("update deposit")
        const response = await client.post<CartData>(`${STORE_API_BASE}/cart/update-item`, {
          key: depositProductKey,
          quantity: depositCounter,
        }, getRequestConfig());
        extractNonce(response);

        setData(response.data);
        return 
      }
      
      if (depositCounter > 0 && !isDepositProductPresent) {
        // console.log("add deposit")
        const response = await client.post<CartData>(`${STORE_API_BASE}/cart/add-item`, {
          id: depositProductId,
          quantity: depositCounter,
        }, getRequestConfig());
        extractNonce(response);
        setData(response.data);
        return 
      }

    } catch(error) {
      console.log(error)
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const addItem = useCallback(async (id: number, quantity: number) => {
    let item: CartItem | undefined;

    try {
      // add item to cart
      setIsLoading(true);
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/add-item`, {
        id,
        quantity,
      }, getRequestConfig());

      // console.log(response.data)

      extractNonce(response);

      // console.log(response)
      setData(response.data);

      addOrRemoveDepositInBaset(response.data.items)

      item = getItem(id, response.data.items);
      setNewestItem(item);
      setIsLoading(false);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
      setIsLoading(false);
    }

    try {
      if(item) {
        trackAddItem(getTrackingItem(item));
      }
    } catch(error) {
      console.error(error);
    }
  }, [client, extractNonce, getItem, getRequestConfig, getTrackingItem, trackAddItem]);

  const removeItem = useCallback(async (key: string) => {
    try {
      setIsLoading(true);
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/remove-item`, {
        key,
      }, getRequestConfig()); 

      extractNonce(response);
      setData(response.data);

      await addOrRemoveDepositInBaset(response.data.items)
      setIsLoading(false)
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
      setIsLoading(false)
    }
  }, [client, extractNonce, getRequestConfig]);

  const updateItem = useCallback(async (key: string, quantity: number) => {
    try {
      setIsLoading(true)
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/update-item`, {
        key,
        quantity,
      }, getRequestConfig());

      extractNonce(response);
      setData(response.data);

      await addOrRemoveDepositInBaset(response.data.items)
      setIsLoading(false)
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
      setIsLoading(false)
    }
  }, [client, extractNonce, getRequestConfig]);

  const clearItems = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await client.delete(
        `${STORE_API_BASE}/cart/items`,
        getRequestConfig(),
      );

      extractNonce(response);
      setIsLoading(false);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
      setIsLoading(false)
    }
  }, [client, extractNonce, getRequestConfig]);

  const addCoupon = useCallback(async (code: string) => {
    try {
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/apply-coupon`, {
        code,
      }, getRequestConfig());

      extractNonce(response);
      setData(response.data);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const removeCoupon = useCallback(async (code: string) => {
    try {
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/remove-coupon`, {
        code,
      }, getRequestConfig());

      extractNonce(response);
      setData(response.data);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const clearCoupons = useCallback(async () => {
    try {
      const response = await client.delete(
        `${STORE_API_BASE}/cart/coupons`,
        getRequestConfig(),
      );

      extractNonce(response);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const clearCart = useCallback(async () => {
    await clearItems();
    await clearCoupons();

    await fetch();
  }, [clearCoupons, clearItems, fetch]);

  const updateCustomer = useCallback(async (
    billing?: Partial<Billing>,
    shipping?: Partial<Address>,
  ) => {
    try{
      const response = await client.post<CartData>(`${STORE_API_BASE}/cart/update-customer`, {
        billing_address: billing,
        shipping_address: shipping,
      }, getRequestConfig());

      extractNonce(response);
      setData(response.data);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const draftOrder = useCallback(async () => {
    try {
      const response = await client.get<CheckoutData>(
        `${STORE_API_BASE}/checkout`,
        getRequestConfig(),
      );

      extractNonce(response);
      return response.data;
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const fetchOrderData = useCallback(async (orderId: string | null, firstName: string | null, lastName: string | null, zip?: string | null) => {
    try {
        const response = await client.get<Order>(
            `/hdc/v1/order-summary/${orderId}?first_name=${firstName}&last_name=${lastName}&zip=${zip}`,
            getRequestConfig(),
        );

        extractNonce(response);
        return response.data;
    } catch (error) {
        await handleApiError(error as AxiosError<StoreApiError>);
    }
  }, [client, extractNonce, getRequestConfig]);

  const confirmOrder = async (orderId: string, payload: any): Promise<Order | undefined> => {
    const config = getRequestConfig();

    try {
      const response = await client.post<Order>(`/hdc/v1/confirm-payment/${orderId}`, payload, config);
  
      if (response.status !== 200) {
        throw new Error(`Server responded with status code: ${response.status}`);
      }

      try {
        trackPurchase({
          orderNumber: Number(orderId),
          items: getTrackingItems(data?.items),
          coupons: getTrackingCoupons(data?.coupons),
          total: getTrackingPrice(data?.totals.total_price, data?.totals),
        });
        // console.log("Tracking purchase")
        // console.log(data)
      } catch(error) {
        console.error(error);
      }

  
      return response.data;
    } catch (error) {
      console.error("Error confirming order:", error);
      return undefined;
    }
  };
  

  const updateOrderWithPaypalData = useCallback(async (
    id: string,
    paypalData: any,  // assuming any type for simplicity; you may want to create a type/interface for this
    paymentMethod: string,
  ): Promise<Order | undefined> => {
    const config = getRequestConfig();
  
  // Check if the Nonce header is correctly set
  if (!config.headers || !config.headers['Nonce']) {
    throw new Error("Nonce header is missing or not correctly set.");
  }

  // Check if order ID, PayPal data, and payment method are provided
  if (!id || !paypalData || !paymentMethod) {
    throw new Error("Order ID, PayPal data, or payment method is missing.");
  }

  
    try {
      const paymentData = {
        _transaction_id: paypalData.paypalOrderId,
        _payment_method: paymentMethod,
        _payment_method_title: paymentMethod,  // adjust as needed
        _transaction_date: new Date().toISOString(),  // assuming the transaction date is now
        // _billing_first_name: paypalData.purchase_units[0].shipping.name.full_name.split(' ')[0],
        // _billing_last_name: paypalData.purchase_units[0].shipping.name.full_name.split(' ')[1],
        // _billing_address_1: paypalData.purchase_units[0].shipping.address.address_line_1,
        // _billing_city: paypalData.purchase_units[0].shipping.address.admin_area_2,
        // _billing_postcode: paypalData.purchase_units[0].shipping.address.postal_code,
        // _billing_country: paypalData.purchase_units[0].shipping.address.country_code,
        // ... other payment related data
      };

      const response = await client.put<Order | ErrorResponse>(`/hdc/v2/orders/${id}`, {
        payment_data: paymentData,
      }, config);
  
      if (response.status !== 200 || 'error' in response.data) {
        throw new Error(`Server responded with status code: ${response.status} or error in data.`);
      }
  
      return response.data as Order;
    } catch (error) {
      await handleApiError(error as AxiosError<StoreApiError>);
      return undefined;
    }
  }, [client, getRequestConfig]);
  

  function convertToMainUnit(priceInSmallestUnit: string | number): string {
    return (parseFloat(priceInSmallestUnit.toString()) / 100).toFixed(2);
  }

  const createPaypalExpressOrderDetails = (cart: CartData, orderId: string, paymentMethod: string) => {
    if (!cart || !cart.items || cart.items.length === 0) {
      throw new Error("Cart is empty or invalid.");
    }
  
    const items = cart.items.map(item => ({
      name: item.name,
      unit_amount: {
        currency_code: cart.totals.currency_code,
        value: convertToMainUnit(item.prices.price), // Adjust this if the structure is different
      },
      quantity: item.quantity.toString(),
    }));


    const orderDetails: LooseObject = {
      intent: "CAPTURE",
      purchase_units: [{
        reference_id: orderId,
        description: `Order ID: ${orderId}`,
        amount: generatePaypalAmount(cart),
        items: items,
      }],
    }
  
    return orderDetails;
  };

  const generatePaypalAmount = (cart: CartData) => {
    return {
      currency_code: cart.totals.currency_code,
      value: convertToMainUnit(cart.totals.total_price), // Adjust this if the structure is different
      breakdown: {
        item_total: {
          currency_code: cart.totals.currency_code,
          value: convertToMainUnit(parseFloat(cart.totals.total_items)  + parseFloat(cart.totals.total_items_tax)), // Adjust this if the structure is different
        },
        shipping: {
          currency_code: cart.totals.currency_code,
          value: convertToMainUnit(parseFloat(cart.totals.total_shipping) + parseFloat(cart.totals.total_shipping_tax)), // Adjust this if the structure is different
        },
        discount: {
          currency_code: cart.totals.currency_code,
          value: convertToMainUnit(parseFloat(cart.totals.total_discount) + parseFloat(cart.totals.total_discount_tax)), // Adjust this if the structure is different
        },
      }
    }
  }

  const createPaypalOrderDetails = (cart: CartData, orderId: string, paymentMethod: string) => {
    if (!cart || !cart.items || cart.items.length === 0) {
      throw new Error("Cart is empty or invalid.");
    }
  
    const items = cart.items.map(item => ({
      name: item.name,
      unit_amount: {
        currency_code: cart.totals.currency_code,
        value: convertToMainUnit(item.prices.price), // Adjust this if the structure is different
      },
      quantity: item.quantity.toString(),
    }));
  
    const shippingAddress = cart.shipping_address ? {
      name: {
        full_name: `${cart.shipping_address.first_name} ${cart.shipping_address.last_name}`
      },
      address: {
        address_line_1: cart.shipping_address.address_1,
        address_line_2: cart.shipping_address.address_2 || '',  // Ensure non-null value
        admin_area_2: cart.shipping_address.city,
        postal_code: cart.shipping_address.postcode,
        country_code: cart.shipping_address.country,
      }
    } : undefined;

    const billingAddress = cart.billing_address ? {
      address_line_1: cart.billing_address.address_1,
      address_line_2: cart.billing_address.address_2 || '',  // Ensure non-null value
      admin_area_2: cart.billing_address.city,
      postal_code: cart.billing_address.postcode,
      country_code: cart.billing_address.country,
    } : undefined;

    const orderDetails: LooseObject = {
      intent: "CAPTURE",
      purchase_units: [{
        reference_id: orderId,
        description: `Order ID: ${orderId}`,
        amount: generatePaypalAmount(cart),
        items: items,
        shipping: shippingAddress,
      }],
    }

    if (paymentMethod === "card") {
      orderDetails.payer = {
        name: {
          given_name: cart.shipping_address.first_name,
          surname: cart.shipping_address.last_name
        },
        address: billingAddress,
        email_address: cart.billing_address.email
      }
    }
  
    return orderDetails;
  };
  
  const checkout = useCallback(async ({
    billing,
    shipping,
    paymentGateway,
    paymentData,
  }: CheckoutArgs) => {
    let response;

    try {
      response = await client.post<CheckoutData>(`${STORE_API_BASE}/checkout`, {
        billing_address: billing,
        shipping_address: shipping,
        payment_method: paymentGateway,
        payment_data: paymentData,
      }, getRequestConfig());

      extractNonce(response);
    } catch(error) {
      await handleApiError(error as AxiosError<StoreApiError>);
    }

    // try {
    //   if(response) {
    //     trackPurchase({
    //       orderNumber: response.data.order_id,
    //       items: getTrackingItems(data?.items),
    //       coupons: getTrackingCoupons(data?.coupons),
    //       total: getTrackingPrice(data?.totals.total_price, data?.totals),
    //     });
    //   }
    // } catch(error) {
    //   console.error(error);
    // }

    return response?.data;
  }, [
    client,
    data,
    extractNonce,
    getRequestConfig,
    getTrackingCoupons,
    getTrackingItems,
    getTrackingPrice,
    trackPurchase,
  ]);

  const value = useMemo(() => ({
    cart: data,
    newestItem,
    fetch,
    addItem,
    removeItem,
    updateItem,
    clearItems,
    addCoupon,
    addOrRemoveDepositInBaset,
    removeCoupon,
    clearCoupons,
    clearCart,
    updateCustomer,
    draftOrder,
    createPaypalOrderDetails,
    confirmOrder,
    checkout,
    fetchOrderData,
    updateOrderWithPaypalData,
    isLoading,
    createPaypalExpressOrderDetails,
    fetchItemBySlug,
  } as CartContextValue), [
    data,
    newestItem,
    fetch,
    addItem,
    removeItem,
    updateItem,
    clearItems,
    addCoupon,
    removeCoupon,
    clearCoupons,
    clearCart,
    updateCustomer,
    draftOrder,
    createPaypalOrderDetails,
    confirmOrder,
    checkout,
    fetchOrderData,
    updateOrderWithPaypalData,
    createPaypalExpressOrderDetails
  ]);

  return (
    <CartContext.Provider value={value}>
      {children}
    </CartContext.Provider>
  );
}

import React, { ReactElement, useCallback, useContext, useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Optional } from '../../../shared/api/types';
import { CartContext, QUANTITY_MAX, QUANTITY_MIN } from '../../../shared/cart/cart-provider';
import NumberControl, { NumberControlBackground } from '../../../shared/form/number-control/number-control';

type CartItemFormData = {
  quantity: number;
};

type Props = {
  itemKey: Optional<string>;
  storedQuantity: Optional<number>;
  background: NumberControlBackground;
  className?: string;
  handleRemove?: () => void;
};

const DEFAULT_VALUE = 1;
const DEBOUNCE_DELAY = 750;

export default function CartItemForm({
  itemKey,
  storedQuantity,
  background,
  className,
  handleRemove
}: Props): ReactElement {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { updateItem, cart, addOrRemoveDepositInBaset } = useContext(CartContext);

  const formMethods = useForm<CartItemFormData>({
    defaultValues: {
      quantity: DEFAULT_VALUE,
    },
  });
  const {
    reset,
    handleSubmit,
    watch,
  } = formMethods;

  useEffect(() => {
    reset({
      quantity: storedQuantity ?? DEFAULT_VALUE,
    });
  }, [reset, storedQuantity]);

  const submit = useCallback(async ({ quantity }: CartItemFormData) => {
    if(!itemKey) return;

    try {
      await updateItem(itemKey, Number(quantity));
    } catch {
    }
  }, [itemKey, updateItem, cart]);

  useEffect(() => {
    const callback = handleSubmit(submit);

    const subscription = watch(() => {
      if(timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(callback, DEBOUNCE_DELAY);
    });

    return () => {
      if(timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      subscription.unsubscribe();
    };
  }, [submit, watch, handleSubmit]);

  return (
    <FormProvider {...formMethods}>
      <form className={className}>
        <NumberControl
          id="quantity"
          handleRemove={handleRemove}
          placeholder="X"
          background={background}
          min={QUANTITY_MIN}
          max={QUANTITY_MAX}
          required
          small
        />
      </form>
    </FormProvider>
  );
}

import React, { ReactElement, useContext, useEffect } from 'react';
import CartDrawerItem from '../cart-drawer-item/cart-drawer-item';
import { CartContext } from '../../shared/cart/cart-provider';
import './cart-drawer-items.scss';
import Price, { addToPrice } from '../../shared/price/price';

export default function CartDrawerItems(): ReactElement {
  const { cart, fetch } = useContext(CartContext);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        await fetch();
      } catch {}
    })();
  }, [fetch]);

  const depositNameString = "Pfand 0,25€";

  return (
    <div className="block block--vertical">
      {cart?.items.filter(i => i.name !== depositNameString).map((item) => (
        <CartDrawerItem
          key={item.id}
          itemKey={item.key}
          name={item.name}
          short_description={item.short_description}
          item_data={item.item_data}
          variation={item.variation}
          images={item.images}
          permalink={item.permalink}
          quantity={item.quantity}
          totals={item.totals}
          rest={item}
          className="cart__item"
        />
      ))}
      {cart?.items.filter(i => i.name === depositNameString).map((item) => (
        <div className="block block--vertical mt-4">
          <div className="block block--vertical">
            <div className="text-bold">Pfand</div>
            <div className='flex justify-between mt-2'>
              <div className=''>{item.quantity} x {(Number(item.prices.price) / 100).toFixed(2)}€</div>
              <div className='text-bold'>              
                <Price
                  value={addToPrice(item.totals.line_total, item.totals.line_total_tax)}
                  currency={item.totals}
                />
              </div>
            </div>

          </div>
        </div>
      ))}
    </div>
  );
}
